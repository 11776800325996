import { callApi } from '../utils/api-utils'
import authConfig from '../config/auth'
import Axios from 'axios'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const login = {
  login: {
    ...defaults.methods.POST,
    uri: '/api/login'
  },

  verifyToken: {
    ...defaults.methods.GET,
    uri: '/api/verify-token'
  },

  refreshToken: {
    ...defaults.methods.POST,
    uri: '/api/login/get-refresh-token'
  },

  verifyRefreshToken: {
    ...defaults.methods.POST,
    uri: '/api/verify-refresh-token'
  }
}
// verify-refresh-token
export const userLogin = body => {
  return callApi({ uriEndPoint: login.login, body })
    .then(res => {
      if (res.statusCode === 200) {
        localStorage.setItem(authConfig.storageTokenKeyName, res.data.token)
        localStorage.setItem(authConfig.storageUId, res.data.u_id)
        localStorage.setItem(authConfig.roleUId, res.data.rol_u_id)
        localStorage.setItem(authConfig.storageRoleName, res.data.role_name)
        localStorage.setItem('userData', JSON.stringify(res.data))

        return res
      } else {
        delete res.data

        return res
      }
    })
    .catch(err => {
      throw err
    })
}

export const verifyToken = () => {
  return callApi({ uriEndPoint: login.verifyToken })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err
    })
}

export const refreshToken = body => {
  return Axios({
    method: login.refreshToken.method,
    url: process.env.REACT_APP_API_ENDPOINT + login.refreshToken.uri,
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
}

export const permissionList = () => {
  return callApi({ uriEndPoint: login.getPermissions })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err
    })
}