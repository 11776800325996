import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { callApi } from '../utils/api-utils'
import {
  faAd,
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faEdit,
  faExternalLinkAlt,
  faPlus,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import {
  Col,
  Row,
  Card,
  Image,
  Button,
  Table,
  // Dropdown,
  ProgressBar,
  Modal,
  Form

  // Pagination,
  // ButtonGroup
} from '@themesberg/react-bootstrap'
import { useUserContext } from '../context/MyContext'

// import { Link } from 'react-router-dom'

import { pageVisits, pageTraffic, pageRanking } from '../data/tables'
import commands from '../data/commands'
import axios from 'axios'
// import { app } from '../config/firebase'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const orderListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/orders/total-orders'
}

const quizPoint = {
  ...defaults.methods.GET,
  uri: '/api/course/student-quiz-score'
}
const coachListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/staff/staff-list'
}
const coachDeleteEndPoint = {
  ...defaults.methods.DELETE,
  uri: '/api/staff/delete-staff/:id'
}

const coachPasswordUpdateEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/staff/update-password/:id'
}

const userListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/user'
}
const userDelete = {
  ...defaults.methods.GET,
  uri: '/api/user/delete/:id'
}
const userStatusUpdate = {
  ...defaults.methods.POST,
  uri: '/api/user/status-update/:id'
}

const listEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/questions/questions'
}
const newsLetterListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/news-letter/get'
}
const newsLetterDeleteEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/news-letter/delete/:id'
}
const deleteEndPoint = {
  ...defaults.methods.DELETE,
  uri: '/api/questions/delete/questions/:id'
}
const deleteQuestionAndAnswoerEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/user/user-delete-question/:id'
}
const commentApprove = {
  ...defaults.methods.POST,
  uri: '/api/comments/approve-comment/:id'
}
const answerListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/answers/answers'
}
const InAppPurchaseUserListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/purchase/purchased-data'
}
const inAppPurchaseList = {
  ...defaults.methods.GET,
  uri: '/api/purchase/purchase-id-list'
}
const inAppPurchaseDeleteEndPoint = {
  ...defaults.methods.DELETE,
  uri: '/api/purchase/purchase-id-delete/:id'
}
const deleteFreeWebinarEndpoint = {
  ...defaults.methods.GET,
  uri: '/api/webinar/delete/:id'
}

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp
  const valueTxtColor = value < 0 ? 'text-danger' : 'text-success'

  return value ? (
    <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className='fw-bold ms-1'>
        {Math.abs(value)}
        {suffix}
      </span>
    </span>
  ) : (
    '--'
  )
}

export const PageVisitsTable = () => {
  const TableRow = props => {
    const { pageName, views, returnValue, bounceRate } = props
    const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp
    const bounceTxtColor = bounceRate < 0 ? 'text-danger' : 'text-success'

    return (
      <tr>
        <th scope='row'>{pageName}</th>
        <td>{views}</td>
        <td>${returnValue}</td>
        <td>
          <FontAwesomeIcon icon={bounceIcon} className={`${bounceTxtColor} me-3`} />
          {Math.abs(bounceRate)}%
        </td>
      </tr>
    )
  }

  return (
    <Card border='light' className='shadow-sm'>
      <Card.Header>
        <Row className='align-items-center'>
          <Col>
            <h5>Page visits</h5>
          </Col>
          <Col className='text-end'>
            <Button variant='secondary' size='sm'>
              See all
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className='align-items-center table-flush'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>Page name</th>
            <th scope='col'>Page Views</th>
            <th scope='col'>Page Value</th>
            <th scope='col'>Bounce rate</th>
          </tr>
        </thead>
        <tbody>
          {pageVisits.map(pv => (
            <TableRow key={`page-visit-${pv.id}`} {...pv} />
          ))}
        </tbody>
      </Table>
    </Card>
  )
}

export const PageTrafficTable = () => {
  const TableRow = props => {
    const { id, source, sourceIcon, sourceIconColor, sourceType, category, rank, trafficShare, change } = props

    return (
      <tr>
        <td>
          <Card.Link href='#' className='text-primary fw-bold'>
            {id}
          </Card.Link>
        </td>
        <td className='fw-bold'>
          <FontAwesomeIcon icon={sourceIcon} className={`icon icon-xs text-${sourceIconColor} w-30`} />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : '--'}</td>
        <td>{rank ? rank : '--'}</td>
        <td>
          <Row className='d-flex align-items-center'>
            <Col xs={12} xl={2} className='px-0'>
              <small className='fw-bold'>{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className='px-0 px-xl-1'>
              <ProgressBar variant='primary' className='progress-lg mb-0' now={trafficShare} min={0} max={100} />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix='%' />
        </td>
      </tr>
    )
  }

  return (
    <Card border='light' className='shadow-sm mb-4'>
      <Card.Body className='pb-0'>
        <Table responsive className='table-centered table-nowrap rounded mb-0'>
          <thead className='thead-light'>
            <tr>
              <th className='border-0'>#</th>
              <th className='border-0'>Traffic Source</th>
              <th className='border-0'>Source Type</th>
              <th className='border-0'>Category</th>
              <th className='border-0'>Global Rank</th>
              <th className='border-0'>Traffic Share</th>
              <th className='border-0'>Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map(pt => (
              <TableRow key={`page-traffic-${pt.id}`} {...pt} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export const RankingTable = () => {
  const TableRow = props => {
    const {
      country,
      countryImage,
      overallRank,
      overallRankChange,
      travelRank,
      travelRankChange,
      widgetsRank,
      widgetsRankChange
    } = props

    return (
      <tr>
        <td className='border-0'>
          <Card.Link href='#' className='d-flex align-items-center'>
            <Image src={countryImage} className='image-small rounded-circle me-2' />
            <div>
              <span className='h6'>{country}</span>
            </div>
          </Card.Link>
        </td>
        <td className='fw-bold border-0'>{overallRank ? overallRank : '-'}</td>
        <td className='border-0'>
          <ValueChange value={overallRankChange} />
        </td>
        <td className='fw-bold border-0'>{travelRank ? travelRank : '-'}</td>
        <td className='border-0'>
          <ValueChange value={travelRankChange} />
        </td>
        <td className='fw-bold border-0'>{widgetsRank ? widgetsRank : '-'}</td>
        <td className='border-0'>
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    )
  }

  return (
    <Card border='light' className='shadow-sm'>
      <Card.Body className='pb-0'>
        <Table responsive className='table-centered table-nowrap rounded mb-0'>
          <thead className='thead-light'>
            <tr>
              <th className='border-0'>Country</th>
              <th className='border-0'>All</th>
              <th className='border-0'>All Change</th>
              <th className='border-0'>Travel & Local</th>
              <th className='border-0'>Travel & Local Change</th>
              <th className='border-0'>Widgets</th>
              <th className='border-0'>Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map(r => (
              <TableRow key={`ranking-${r.id}`} {...r} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export const TransactionsTable = () => {
  const [orders, setOrders] = useState([])

  useEffect(() => {
    getOrders()
  }, [])

  async function getOrders() {
    await callApi({ uriEndPoint: orderListEndPoint })
      .then(res => {
        if (res?.orders) {
          setOrders(res.orders)
        }
      })
      .catch(e => console.log(e))
  }

  const TableRow = props => {
    const { studentname, coursename, amount, studentemail, category, date } = props
    //  const statusVariant = txStatus === "SUCCESS" ? "success": "danger"

    return (
      <tr>
        <td>
          <span className='fw-normal'>{moment(date).format('DD-MM-YYYY')}</span>
        </td>
        <td>
          <span className='fw-normal'>{studentemail}</span>
        </td>
        <td>
          <span className='fw-normal'>{studentname}</span>
        </td>

        <td>
          <span className='fw-normal'>{category}</span>
        </td>
        <td>
          <span className='fw-normal'>{coursename}</span>
        </td>
        <td>
          <span className={`fw-normal text-danger`}>{(category === 'international' ? '$' : '₹') + ' ' + amount}</span>
        </td>
        <td></td>
      </tr>
    )
  }

  return (
    <Card border='light' className='table-wrapper table-responsive shadow-sm'>
      <button
        style={{
          backgroundColor: '#fff',
          border: 'none',
          textTransform: 'uppercase'
        }}
        onClick={() => getOrders()}
      >
        Click to Refresh
      </button>
      <Card.Body className='pt-0'>
        <Table hover className='user-table align-items-center'>
          <thead>
            <tr>
              <th className='border-bottom'>Date</th>
              <th className='border-bottom'>Student Email</th>
              <th className='border-bottom'>Student Name</th>
              <th className='border-bottom'>Category</th>
              <th className='border-bottom'>Course Name</th>
              <th className='border-bottom'>Amount</th>
              {/* <th className="border-bottom">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {orders.map(t => (
              <TableRow key={`transaction-${t.orderId}`} {...t} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export const OrdersTable = () => {
  const [orders, setOrders] = useState([])

  useEffect(() => {
    getOrders()
  }, [])

  async function getOrders() {
    await callApi({ uriEndPoint: orderListEndPoint })
      .then(res => {
        if (res?.orders) {
          setOrders(res.orders)
        }
      })
      .catch(e => console.log(e))
  }

  const TableRow = props => {
    const { studentname, coursename, status, studentemail, category, date, amount, index } = props
    //  const statusVariant = txStatus === "SUCCESS" ? "success": "danger"

    return (
      <tr>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{moment(date).format('DD-MM-YYYY')}</span>
        </td>
        <td>
          <span className='fw-normal'>{studentemail}</span>
        </td>
        <td>
          <span className='fw-normal'>{studentname}</span>
        </td>

        <td>
          <span className='fw-normal'>{category}</span>
        </td>
        <td>
          <span className='fw-normal'>{coursename}</span>
        </td>
        <td>
          <span className='fw-normal'>{Number(amount) / 100}</span>
        </td>
        <td>
          <span className={`fw-normal text-success`}>{status}</span>
        </td>
        <td></td>
      </tr>
    )
  }

  return (
    <>
      <Button
        style={{
          textTransform: 'uppercase',
          fontSize: '12px',
          marginBottom: '10px'
        }}
        onClick={() => getOrders()}
      >
        Click to Refresh
      </Button>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Date
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Student Email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Student Name
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Category
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Course Name
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Amount
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Status
                </th>
                {/* <th className="border-bottom">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {orders.map((t, index) => (
                <TableRow key={`transaction-${t.orderId}`} {...t} index={index} />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}

export const LoggerTable = () => {
  const [loggerData, setLoggerData] = useState([])
  const [selectName, setSelectName] = useState('admin-api')
  const [dateData, setDateData] = useState(null)
  const [dateValidation, setDateValidation] = useState(false)

  const DateOnChange = value => {
    setDateData(value)
    setDateValidation(false)
  }

  async function getLoggerData() {
    if (dateData === null) {
      setDateValidation(true)
    }
    if (dateData !== null) {
      const body = { type: selectName, date: dateData }

      await axios
        .post(`/api/logger/logger-user`, body)
        .then(res => {
          setLoggerData(res.data.Data)
        })
        .catch(e => alert(e))
    }
  }

  return (
    <Card border='light' className='table-wrapper table-responsive shadow-sm'>
      <div style={{ padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <select
              name='viewCategory'
              onChange={e => {
                setSelectName(e.target.value)
                setDateValidation(false)
              }}
              style={{ padding: '8px 10px 8px 10px' }}
            >
              <option>admin-api</option>
            </select>
          </div>
          <div>
            <input
              type='date'
              style={{ padding: '8px 10px 8px 10px' }}
              max={moment().format('YYYY-MM-DD')}
              onChange={e => DateOnChange(e.target.value)}
            />
            {dateValidation && <div style={{ color: 'red' }}>Please select the Date</div>}
          </div>
          <div>
            <Button onClick={getLoggerData}>view Logs</Button>
          </div>
        </div>

        {loggerData.length !== 0 && (
          <div style={{ marginTop: '30px' }}>
            <textarea
              style={{ width: '100%' }}
              rows={10}
              value={Object.keys(loggerData).length !== 0 ? loggerData : 'No Data Found'}
              disabled
            />
          </div>
        )}
      </div>
    </Card>
  )
}

export const FeedbackTable = () => {
  const [feedBackData, setFeedBackData] = useState([])

  async function getLoggerData() {
    await axios
      .get(`/api/feedback/feedback-list`)
      .then(res => {
        setFeedBackData(res?.data?.data || [])
      })
      .catch(e => alert(e))
  }

  useEffect(() => {
    getLoggerData()
  }, [])

  const TableRow = props => {
    const { course_id, chapter_id, lesson_id, student_id, message } = props
    //  const statusVariant = txStatus === "SUCCESS" ? "success": "danger"

    return (
      <tr>
        <td>
          <span className='fw-normal'>{course_id?.nameOfCourse}</span>
        </td>
        <td>
          <span className='fw-normal'>{chapter_id?.chapterName}</span>
        </td>

        <td>
          <span className='fw-normal'>{lesson_id?.lessonName}</span>
        </td>
        <td>
          <span className='fw-normal'>{student_id?.email}</span>
        </td>
        <td>
          <span className='fw-normal'>{message}</span>
        </td>
      </tr>
    )
  }

  return (
    <Card border='light' className='table-wrapper table-responsive shadow-sm'>
      <Table hover className='user-table align-items-center'>
        <thead>
          <tr>
            <th className='border-bottom'>Course Name</th>
            <th className='border-bottom'>Chapter Name</th>
            <th className='border-bottom'>Lesson Name</th>
            <th className='border-bottom'>Student Email</th>
            <th className='border-bottom'>Review</th>
          </tr>
        </thead>
        <tbody>
          {feedBackData.map(t => (
            <TableRow key={`review-${t.orderId}`} {...t} />
          ))}
        </tbody>
      </Table>
    </Card>
  )
}

export const QuestionAnswersTable = () => {
  const [questionData, setQuestionData] = useState([])
  const { user } = useUserContext()

  const history = useHistory()
  const [recallApi, setRecallApi] = useState('')
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')

  const handelOpen = () => {
    setOpen(!open)
  }

  const questionPage = _id => {
    history.push(`/view-question-from/${_id}`)
  }

  const deleteQuestion = async id => {
    handelOpen()
    setId(id)
  }

  const confirmClick = async () => {
    await callApi({ uriEndPoint: deleteQuestionAndAnswoerEndPoint, pathParams: { id: id || null } })
      .then(res => {
        setRecallApi(Math.floor(Math.random(0, 1) * 100).toString())
        alert(res?.message || 'Record deleted successfully')
        handelOpen()
      })
      .catch(e => console.log(e))
  }

  async function getLoggerData() {
    await axios
      .get(`/api/user/user-question`)
      .then(res => {
        console.log(`🚀  ~ file: Tables.js:569 ~ getLoggerData ~ res:`, res)

        setQuestionData(res?.data?.data || [])
      })
      .catch(e => alert(e))
  }

  useEffect(() => {
    getLoggerData()
  }, [recallApi])

  const TableRow = props => {
    const { email, phone, created_at, _id } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>

        <td>
          <span className='fw-normal'>{created_at ? moment(created_at).format('DD-MM-YYYY') : '-'}</span>
        </td>
        {user.role === 'super-admin' && (
          <td>
            <Button style={{ marginLeft: '5px' }} onClick={() => questionPage(_id)}>
              View
            </Button>
          </td>
        )}
        {user.role === 'super-admin' && (
          <td>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => deleteQuestion(_id)}
              data-toggle='modal'
              data-target='#exampleModalCenter'
            >
              Delete
            </Button>
          </td>
        )}
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Table hover className='user-table align-items-center'>
          <thead>
            <tr>
              <th className='border-bottom'>Name</th>
              <th className='border-bottom'>Email</th>
              <th className='border-bottom'>Date</th>
              <th className='border-bottom'>View</th>
              <th className='border-bottom'>Delete</th>
            </tr>
          </thead>
          <tbody>
            {questionData.map(t => (
              <TableRow key={`review-${t.orderId}`} {...t} />
            ))}
          </tbody>
        </Table>
      </Card>
      <div
        className='modal fade'
        id='exampleModalCenter'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => confirmClick()}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const CommentsTable = () => {
  const [feedBackData, setFeedBackData] = useState([])
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')
  const [recallApi, setRecallApi] = useState('')
  const [openApprove, setOpenApprove] = useState(false)
  const [approvedId, setApprovedId] = useState('')

  const handelOpen = () => {
    setOpen(!open)
  }
  const handelOpenApprove = () => {
    setOpenApprove(!openApprove)
  }

  const viewMsg = async message => {
    handelOpen()
    setId(message)
  }
  const approveMsgFun = id => {
    setApprovedId(id)
    handelOpenApprove()
  }

  async function getLoggerData() {
    await axios
      .get(`/api/comments/list-comments`)
      .then(res => {
        setFeedBackData(res?.data?.data || [])
      })
      .catch(e => alert(e))
  }

  useEffect(() => {
    getLoggerData()
  }, [recallApi])

  const confirmClick = async () => {
    await callApi({ uriEndPoint: commentApprove, pathParams: { id: approvedId || null } })
      .then(res => {
        setRecallApi(Math.floor(Math.random(0, 1) * 100).toString())
        alert(res?.message || 'Record Updated successfully')
        handelOpenApprove()
      })
      .catch(e => console.log(e))
  }

  const TableRow = props => {
    const { course_id, student_id, message, is_approved, _id } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{course_id.nameOfCourse}</span>
        </td>

        <td>
          <span className='fw-normal'>{student_id.email}</span>
        </td>
        <td>
          <Button
            style={{ marginLeft: '5px', backgroundColor: 'black', padding: '0.5rem 2rem' }}
            onClick={() => viewMsg(message)}
            data-toggle='modal'
            data-target='#exampleModalCenter'
          >
            View
          </Button>
        </td>
        <td>
          <Button
            style={{
              marginLeft: '5px',
              backgroundColor: is_approved ? 'green' : 'red',
              padding: '0.5rem 2rem',
              border: 'none'
            }}
            onClick={() => approveMsgFun(_id)}
            data-toggle='modal'
            data-target='#confirmOrder'
          >
            {is_approved ? 'Approved' : 'Pending'}
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Table hover className='user-table align-items-center'>
          <thead>
            <tr>
              <th className='border-bottom'>Course Name</th>
              <th className='border-bottom'>Student Email</th>
              <th className='border-bottom'>View Message</th>
              <th className='border-bottom'>Approve</th>
            </tr>
          </thead>
          <tbody>
            {feedBackData.map(t => (
              <TableRow key={`review-${t.orderId}`} {...t} />
            ))}
          </tbody>
        </Table>
      </Card>
      <div
        className='modal fade'
        id='exampleModalCenter'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                View Message
              </h5>
            </div>
            <div className='modal-body align-self-center'> {id}</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='confirmOrder'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => confirmClick()}>
                Yes, Approved it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const CouponTable = () => {
  const [Data, setData] = useState([])
  const [couponName, setCouponName] = useState(null)
  const [discountFlat, setDiscountFlat] = useState(null)
  const [couponCode, setCouponCode] = useState(null)
  const [, setCourseName] = useState(null)
  const [, setCurrency] = useState('INR')
  const [error, setError] = useState(false)
  const [errorTwo, setErrorTwo] = useState(false)
  const [errorThree, setErrorThree] = useState(false)
  const [viewData, setViewData] = useState([])
  const [extra, setExtra] = useState(0)
  const [show, setShow] = useState(false)
  const [deleteID, setDeleteId] = useState('')
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const CreateCouponBtn = async () => {
    if (couponName === null) {
      setError(true)
    } else {
      setError(false)
    }
    if (couponCode === null) {
      setErrorTwo(true)
    } else {
      setErrorTwo(false)
    }
    if (discountFlat === null) {
      setErrorThree(true)
    } else {
      setErrorThree(false)
    }

    if (couponName !== null && couponCode !== null && discountFlat !== null) {
      const body = { eventName: discountFlat + '%', eventURL: couponCode, imageUrl: couponName }
      await axios
        .post(`/api/coupon/add-coupon?type=front-end-api`, body)
        .then(res => {
          if (res?.data?.data?.eventName)
            if (Object.keys(res.data).length !== 0) {
              const obj = {
                eventName: res.data.data.eventName,
                eventURL: res.data.data.eventURL,
                imageUrl: res.data.data.imageUrl
              }
              setViewData(pre => [...pre, obj])
              setExtra(extra + 1)
            }
        })
        .catch(e => alert(e))
      // await app
      //   .firestore()
      //   .collection('coupon')
      //   .add({ eventName: discountFlat, eventURL: couponCode, imageUrl: couponName })
      //   .then(res => {
      //     if (res) {
      //       const obj = { eventName: discountFlat, eventURL: couponCode, imageUrl: couponName }
      //       setViewData(pre => [...pre, obj])
      //       setExtra(extra + 1)
      //     }
      //   })
    }
  }

  const removeDiscount = async id => {
    handleShow()
    setDeleteId(id)
  }
  const DeleteConfirm = async () => {
    const filter = viewData.filter(item => item._id != deleteID)

    await axios
      .delete(`/api/coupon/delete-coupon/${deleteID}?type=front-end-api`)
      .then(res => {
        if (res?.data?.data) {
          if (Object.keys(res.data).length !== 0) {
            return null
          }
          handleClose()
        }
      })
      .catch(e => alert(e))
    // await app
    //   .firestore()
    //   .collection('coupon')
    //   .where('eventName', '==', deleteID)
    //   .get()
    //   .then(querySnapshot => {
    //     querySnapshot.docs[0].ref.delete()
    //     handleClose()
    //   })
    //   .catch(err => {
    //     alert(err)
    //   })
    setViewData(filter)
  }

  useEffect(() => {
    const callApi = async () => {
      await axios
        .get(`/api/course?type=front-end-api`)
        .then(res => {
          if (Object.keys(res.data).length !== 0) {
            setData(res?.data?.courses)
          }
        })
        .catch(e => alert(e))

      await axios
        .get(`/api/coupon/get-coupon?type=front-end-api`)
        .then(res => {
          if (Object.keys(res.data).length !== 0) {
            setViewData(res.data.data)
          }
        })
        .catch(e => alert(e))
    }

    callApi()
  }, [])

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <div style={{ display: 'flex', padding: '10px', paddingBottom: '0px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type='text'
              required
              placeholder='Coupon Name'
              style={{ padding: '8px' }}
              onChange={e => setCouponName(e.target.value)}
            />
            {error && <span style={{ color: 'red' }}>Coupon Name Required</span>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type='text'
              required
              placeholder='Flat Discount'
              style={{ padding: '8px' }}
              onChange={e => setDiscountFlat(e.target.value)}
            />
            {errorTwo && <span style={{ color: 'red' }}>Coupon Name Required</span>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type='text'
              required
              placeholder='Coupon Code'
              style={{ padding: '8px' }}
              onChange={e => setCouponCode(e.target.value)}
            />
            {errorThree && <span style={{ color: 'red' }}>Coupon Name Required</span>}
          </div>
        </div>
        <div style={{ padding: '10px', paddingTop: '0px' }}>
          <select style={{ width: '80%', padding: '8px' }} onChange={e => setCourseName(e.target.value)}>
            <option value=''>Course</option>
            {Data.map((item, index) => {
              return (
                <option key={index + 'couponIndex'} value={item.nameOfCourse}>
                  {item.nameOfCourse}
                </option>
              )
            })}
          </select>
          <select style={{ margin: '0px 5px', padding: '8px' }} required onChange={e => setCurrency(e.target.value)}>
            <option value=''>Currency</option>
            <option value='INR'>INR</option>
            <option value='USD'>USD</option>
          </select>
          <Button onClick={() => CreateCouponBtn()} style={{ cursor: 'pointer' }}>
            Create Coupon
          </Button>
        </div>
      </Card>
      <Card
        border='light'
        className='table-wrapper table-responsive shadow-sm'
        style={{ width: '70%', padding: '10px' }}
      >
        <table>
          <tr>
            <th>Coupon</th>
            <th>Coupon Code</th>
            <th>Flat Discount</th>
          </tr>

          {viewData?.map((item, index) => {
            return (
              <tr key={index + 'deleteCoupon'}>
                <td>
                  <span style={{ cursor: 'pointer' }} onClick={() => removeDiscount(item?._id)}>
                    &#10006;
                  </span>{' '}
                  {item.imageUrl}
                </td>
                <td>{item.eventURL}</td>
                <td>{item.eventName}</td>
              </tr>
            )
          })}
        </table>
      </Card>
      <div className='modal show' style={{ display: 'block', position: 'initial' }}>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Are you sure you want to delete this Coupon</Modal.Title>
          </Modal.Header>
          <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='primary' onClick={DeleteConfirm}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export const StudentsTable = () => {
  const [courses, setCourses] = useState([])
  const [recallApi, setRecallApi] = useState('')
  const [id, setId] = useState('')
  const [updateStudentStatus, setUpdateStudentStatus] = useState(null)

  useEffect(() => {
    Refresh()
  }, [recallApi])

  async function Refresh() {
    await callApi({ uriEndPoint: userListEndPoint })
      .then(res => {
        if (res?.students) {
          setCourses(res.students)
        }
      })
      .catch(e => console.log(e))
  }
  async function deleteUsers() {
    await callApi({ uriEndPoint: userDelete, pathParams: { id: id } })
      .then(() => {
        setRecallApi(Math.floor(Math.random(1) * 100).toString())
        setUpdateStudentStatus(null)
      })
      .catch(e => console.log(e))
  }
  async function StatusUpdateUsers({ id, value }) {
    const body = { status: value }
    await callApi({ uriEndPoint: userStatusUpdate, pathParams: { id: id }, body })
      .then(() => {
        setRecallApi(Math.floor(Math.random(1) * 100).toString())
      })
      .catch(e => console.log(e))
  }

  const TableRow = props => {
    const { _id, legal_name, name, email, phone, index, created_at } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{legal_name ? legal_name : name}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{created_at ? moment(created_at).format('DD-MMM-YYYY') : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        <td>
          <Form.Check // prettier-ignore
            type='switch'
            id='custom-switch'
            data-toggle='modal'
            data-target='#exampleModalCenterStatus'
            checked={props.status}
            onChange={e => {
              setUpdateStudentStatus({ id: props._id, value: e.target.checked })
            }}
          />
        </td>

        <td>
          <Button data-toggle='modal' data-target='#exampleModalCenter' onClick={() => setId(_id)}>
            Delete
          </Button>
          {/* <Button onClick={() => StatusUpdateUsers(props)}>Update Status</Button> */}
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Legal name
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Date
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  phone{' '}
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  status
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {courses.map((t, index) => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
      <div
        className='modal fade'
        id='exampleModalCenter'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => deleteUsers()}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='exampleModalCenterStatus'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure you want to update status?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => StatusUpdateUsers({ id: updateStudentStatus?.id, value: updateStudentStatus?.value })}
              >
                Yes, update it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const TypoFormTable = () => {
  const [courses, setCourses] = useState([])
  const history = useHistory()
  const [recallApi, setRecallApi] = useState('')
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')
  const { user } = useUserContext()

  const handelOpen = () => {
    setOpen(!open)
  }

  useEffect(() => {
    Refresh()
  }, [recallApi])

  async function Refresh() {
    await callApi({ uriEndPoint: listEndPoint })
      .then(res => {
        if (res?.data) {
          setCourses(res?.data)
        }
      })
      .catch(e => console.log(e))
  }

  const questionPage = _id => {
    history.push(`/edit-typo-from/${_id}`)
  }

  const deleteTypo = async id => {
    handelOpen()
    setId(id)
  }

  const confirmClick = async () => {
    await callApi({ uriEndPoint: deleteEndPoint, pathParams: { id: id || null } })
      .then(res => {
        setRecallApi(Math.floor(Math.random(0, 1) * 100).toString())
        alert(res?.message || 'Record deleted successfully')
        handelOpen()
      })
      .catch(e => console.log(e))
  }

  const TableRow = props => {
    const { question, type, error, _id } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{question}</span>
        </td>
        <td>
          <span className='fw-normal'>{type}</span>
        </td>
        <td>
          <span className='fw-normal'>{error}</span>
        </td>

        {user.role === 'super-admin' && (
          <td>
            <Button style={{ marginLeft: '5px' }} onClick={() => questionPage(_id)}>
              Edit
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => deleteTypo(_id)}
              data-toggle='modal'
              data-target='#exampleModalCenter'
            >
              Delete
            </Button>
          </td>
        )}
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom ' style={{ minWidth: '300px' }}>
                  Question
                </th>
                <th className='border-bottom'>type</th>
                <th className='border-bottom' style={{ minWidth: '200px' }}>
                  error
                </th>

                {user.role === 'super-admin' && <th className='border-bottom'>Action</th>}
              </tr>
            </thead>
            <tbody>
              {courses.map(t => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>

      <div
        className='modal fade'
        id='exampleModalCenter'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => confirmClick()}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const NewsLetterFormTable = () => {
  const [newsLetterData, setNewsLetterData] = useState([])

  const [recallApi, setRecallApi] = useState('')
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')
  const { user } = useUserContext()

  const handelOpen = () => {
    setOpen(!open)
  }

  useEffect(() => {
    Refresh()
  }, [recallApi])

  async function Refresh() {
    await callApi({ uriEndPoint: newsLetterListEndPoint })
      .then(res => {
        if (res?.data) {
          setNewsLetterData(res?.data)
        }
      })
      .catch(e => console.log(e))
  }

  const deleteTypo = async id => {
    handelOpen()
    setId(id)
  }

  const confirmClick = async () => {
    await callApi({ uriEndPoint: newsLetterDeleteEndPoint, pathParams: { id: id || null } })
      .then(res => {
        setRecallApi(Math.floor(Math.random(0, 1) * 100).toString())
        alert(res?.message || 'Record deleted successfully')
        handelOpen()
      })
      .catch(e => console.log(e))
  }

  const TableRow = props => {
    const { email, index, _id } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>

        {user.role === 'super-admin' && (
          <td>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => deleteTypo(_id)}
              data-toggle='modal'
              data-target='#exampleModalCenter'
            >
              Delete
            </Button>
          </td>
        )}
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom ' style={{ minWidth: '50px' }}>
                  Sr No.
                </th>
                <th className='border-bottom'>Email</th>

                {user.role === 'super-admin' && <th className='border-bottom'>Action</th>}
              </tr>
            </thead>

            <tbody>
              {newsLetterData.map((t, index) => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>

      <div
        className='modal fade'
        id='exampleModalCenter'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => confirmClick()}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const CoachFormTable = () => {
  const [courses, setCourses] = useState([])
  const history = useHistory()
  const [recallApi, setRecallApi] = useState('')
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')
  const { user } = useUserContext()
  const [addFiled, setAddFiled] = useState({
    password: '',

    error: ''
  })

  const handelChange = e => {
    setAddFiled({ ...addFiled, [e.target.name]: e.target.value })
  }

  const [showPassword, setShowPassword] = useState(false)
  const [errorHandel, setErrorHandel] = useState(false)
  const [updatePassWordModel, setUpdatePassWordModel] = useState(false)
  const handelOpenPassWordUpdate = () => {
    setUpdatePassWordModel(!updatePassWordModel)
  }

  const handelOpen = () => {
    setOpen(!open)
  }

  useEffect(() => {
    Refresh()
  }, [recallApi])

  async function Refresh() {
    await callApi({ uriEndPoint: coachListEndPoint })
      .then(res => {
        if (res?.data) {
          setCourses(res?.data)
        }
      })
      .catch(e => console.log(e))
  }

  const coachPage = _id => {
    history.push(`/edit-coach/${_id}`)
  }

  const deleteCoach = async id => {
    handelOpen()
    setId(id)
  }
  const updatePassWord = async props => {
    handelOpenPassWordUpdate()
    setId(props)
  }

  const confirmClick = async () => {
    await callApi({ uriEndPoint: coachDeleteEndPoint, pathParams: { id: id || null } })
      .then(res => {
        setRecallApi(Math.floor(Math.random(0, 1) * 100).toString())
        alert(res?.message || 'Record deleted successfully')
        handelOpen()
      })
      .catch(e => console.log(e))
  }

  const confirmPasswordClick = async () => {
    delete addFiled.error
    const checkValue = Object.values(addFiled).every(v => v)
    if (checkValue) {
      await callApi({ uriEndPoint: coachPasswordUpdateEndPoint, body: addFiled, pathParams: { id: id._id || null } })
        .then(res => {
          alert(res?.message || 'Record deleted successfully')

          handelOpenPassWordUpdate()
          setId('')
        })
        .catch(e => console.log(e))
    } else {
      setErrorHandel(true)
    }
  }
  const cancelPassword = () => {
    setAddFiled({})
    setId('')
  }

  const TableRow = props => {
    const { email, first_name, last_name, phone, _id } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{first_name + ' ' + last_name}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>

        {user.role === 'super-admin' && (
          <td>
            <Button style={{ marginLeft: '5px' }} onClick={() => coachPage(_id)}>
              Edit
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => deleteCoach(_id)}
              data-toggle='modal'
              data-target='#exampleModalCenter'
            >
              Delete
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => updatePassWord(props)}
              data-toggle='modal'
              data-target='#exampleModalCenter-password'
            >
              Update Password
            </Button>
          </td>
        )}
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom ' style={{ minWidth: '300px' }}>
                  Email
                </th>
                <th className='border-bottom'>Name</th>
                <th className='border-bottom' style={{ minWidth: '200px' }}>
                  phone
                </th>

                {user.role === 'super-admin' && <th className='border-bottom'>Action</th>}
              </tr>
            </thead>
            <tbody>
              {courses.map(t => (
                <TableRow key={`transaction-${t._id}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>

      <div
        className='modal fade'
        id='exampleModalCenter'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => confirmClick()}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='exampleModalCenter-password'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure? {id?.email || ''}
              </h5>
            </div>

            <div className='row p-4'>
              <div className='form-group col-md-12'>
                <label htmlFor='password'>Update Password*</label>
                <div className='d-flex align-items-center position-relative'>
                  <input
                    autoCompleteautoComplete={false}
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    value={addFiled?.password || ''}
                    onChange={handelChange}
                    className='form-control'
                    id='password'
                    placeholder='Password'
                  />
                  <span
                    className='eye-icon position-absolute '
                    style={{ right: '10px', cursor: 'pointer' }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <i className='fa-solid fa-eye'></i> : <i className='fa-solid fa-eye-slash'></i>}
                  </span>
                </div>
              </div>
              {errorHandel && (
                <h5 style={{ color: 'orangered', textTransform: 'capitalize', marginTop: '10px' }}>* require filed</h5>
              )}
            </div>

            <div className='modal-footer justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => confirmPasswordClick()}
              >
                Yes, update it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal' onClick={() => cancelPassword()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export const QuizFormTable = () => {
  const [courses, setCourses] = useState([])

  useEffect(() => {
    Refresh()
  }, [])

  async function Refresh() {
    await callApi({ uriEndPoint: quizPoint })
      .then(res => {
        const filter = res.filter(i => i.totalPercentage !== null)
        console.log(`🚀  ~ file: Tables.js:1477 ~ Refresh ~ res?.data:`, filter)
        if (res) {
          setCourses(filter)
        }
      })
      .catch(e => console.log(e))
  }

  const TableRow = props => {
    const { courseName, studentName, totalPercentage, totalScore, totalQuestion } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{courseName}</span>
        </td>
        <td>
          <span className='fw-normal'>{studentName}</span>
        </td>
        <td>
          <span className='fw-normal'>{totalScore + '/' + totalQuestion}</span>
        </td>
        <td>
          <span className='fw-normal'>{totalPercentage.toFixed(2)}</span>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom ' style={{ minWidth: '300px' }}>
                  Course Name
                </th>
                <th className='border-bottom'>Student Name</th>
                <th className='border-bottom'>Quiz Score</th>

                {<th className='border-bottom'>Percentage</th>}
              </tr>
            </thead>
            <tbody>
              {courses.map(t => (
                <TableRow key={`transaction-${t._id}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const AnswerListTable = () => {
  const [answers, setAnswers] = useState([])
  const history = useHistory()

  useEffect(() => {
    Refresh()
  }, [])

  async function Refresh() {
    await callApi({ uriEndPoint: answerListEndPoint })
      .then(res => {
        if (res?.data) {
          setAnswers(res?.data)
        }
      })
      .catch(e => console.log(e))
  }

  const answerPage = _id => {
    history.push(`/view-answer/${_id}`)
  }

  const TableRow = props => {
    const { answer_id, type, _id } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{answer_id}</span>
        </td>
        <td>
          <span className='fw-normal'>{type}</span>
        </td>

        <td style={{ textAlignLast: 'end' }}>
          <Button style={{ marginLeft: '5px' }} onClick={() => answerPage(_id)}>
            View
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>Answer </th>
                <th className='border-bottom'>type</th>

                <th className='border-bottom ' style={{ textAlignLast: 'end' }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {answers.map(t => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const InAppPurchaseUserListTable = () => {
  const [inAppPurchaseUserData, setInAppPurchaseUserData] = useState([])
  const history = useHistory()

  useEffect(() => {
    Refresh()
  }, [])

  async function Refresh() {
    await callApi({ uriEndPoint: InAppPurchaseUserListEndPoint })
      .then(res => {
        console.log(`🚀  ~ file: Tables.js:1790 ~ Refresh ~ res:`, res)
        if (res?.data) {
          setInAppPurchaseUserData(res?.data)
        }
      })
      .catch(e => console.log(e))
  }

  const ViewInAppPurchaseUserPage = _id => {
    history.push(`/in-app-purchase-user/${_id}`)
  }

  const TableRow = props => {
    const { studentid, productId, _id, inAppPurchaseid } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{studentid?.legal_name}</span>
        </td>
        <td>
          <span className='fw-normal'>{inAppPurchaseid?.coin}</span>
        </td>
        <td>
          <span className='fw-normal'>{productId}</span>
        </td>

        <td style={{ textAlignLast: 'end' }}>
          <Button style={{ marginLeft: '5px' }} onClick={() => ViewInAppPurchaseUserPage(_id)}>
            View
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>Name</th>
                <th className='border-bottom'>Coin</th>
                <th className='border-bottom'>Product ID</th>

                <th className='border-bottom ' style={{ textAlignLast: 'end' }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {inAppPurchaseUserData.map(t => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const InAppPurchaseListTable = () => {
  const [inAppPurchaseData, setInAppPurchaseData] = useState([])
  const [recallApi, setRecallApi] = useState('')
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')

  const handelOpen = () => {
    setOpen(!open)
  }
  const history = useHistory()

  async function Refresh() {
    await callApi({ uriEndPoint: inAppPurchaseList })
      .then(res => {
        if (res) {
          setInAppPurchaseData(res)
        }
      })
      .catch(e => console.log(e))
  }

  const editAndViewInAppPurchase = (_id, type) => {
    if (type === 'edit') {
      history.push(`/edit-in-app-purchase/${_id}`)
    } else {
      history.push(`/view-in-app-purchase/${_id}`)
    }
  }
  const deleteInAppPurchase = _id => {
    setId(_id)
    handelOpen()
  }

  const confirmClick = async () => {
    await callApi({ uriEndPoint: inAppPurchaseDeleteEndPoint, pathParams: { id: id || null } })
      .then(res => {
        setRecallApi(Math.floor(Math.random(0, 1) * 100).toString())
        alert(res?.message || 'Record deleted successfully')
        handelOpen()
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    Refresh()
  }, [recallApi])

  const TableRow = props => {
    const { coin, product_id, _id } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{coin}</span>
        </td>
        <td>
          <span className='fw-normal'>{product_id}</span>
        </td>

        <td style={{ textAlignLast: 'end' }}>
          <Button style={{ marginLeft: '5px' }} onClick={() => editAndViewInAppPurchase(_id, 'edit')}>
            Edit
          </Button>
          <Button variant='info' style={{ marginLeft: '5px' }} onClick={() => editAndViewInAppPurchase(_id, 'view')}>
            View
          </Button>
          <Button
            variant='danger'
            data-toggle='modal'
            data-target='#deleteModalInAppPurchase'
            style={{ marginLeft: '5px' }}
            onClick={() => deleteInAppPurchase(_id)}
          >
            Delete
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>Coins</th>
                <th className='border-bottom'>Purchase Id</th>

                <th className='border-bottom ' style={{ textAlignLast: 'end' }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {inAppPurchaseData.map(t => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>

      <div
        className='modal fade'
        id='deleteModalInAppPurchase'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => confirmClick()}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export const UserReportListTable = ({ userData }) => {
  const history = useHistory()

  const answerPage = _id => {
    history.push(`/user-report-view/${_id}`)
  }

  const TableRow = props => {
    const { email, student_select, _id, legal_name, phone } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{legal_name}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        <td>
          <span className='fw-normal'>{student_select ? student_select.split('_').join(' ').toUpperCase() : null}</span>
        </td>

        <td style={{ textAlignLast: 'end' }}>
          <Button style={{ marginLeft: '5px' }} onClick={() => answerPage(_id)}>
            View
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>Email</th>
                <th className='border-bottom'>Legal Name</th>
                <th className='border-bottom'>phone</th>
                <th className='border-bottom'>type</th>

                <th className='border-bottom ' style={{ textAlignLast: 'end' }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {userData.map(t => (
                <TableRow key={`transaction-${t._id}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const TransactionReportListTable = ({ transactionData }) => {
  const TableRow = props => {
    const { email, coursename, amount } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{coursename}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{amount}</span>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>Course Name</th>
                <th className='border-bottom'>Email</th>
                <th className='border-bottom'>Amount</th>
              </tr>
            </thead>
            <tbody>
              {transactionData?.map(t => (
                <TableRow key={`transaction-${t._id}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const ScholarShipTable = () => {
  const [scholarShipData, setScholarShipData] = useState([])

  const ScholarshipList = async () => {
    await axios
      .get('/api/scholarShip/getScholarShips')
      .then(res => setScholarShipData(res.data.data))
      .catch(e => alert(e))
  }

  useEffect(() => {
    ScholarshipList()
  }, [])

  const TableRow = props => {
    const { _id, name, email, phone } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{name}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>

        <td>
          <Button style={{ cursor: 'pointer' }} onClick={() => (window.location.href = `#/scholarship-view/` + _id)}>
            view
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <Card border='light' className='table-wrapper table-responsive shadow-sm'>
      <Card.Body style={{ height: 432 }} className='pt-0'>
        <Table hover className='user-table align-items-center'>
          <thead>
            <tr>
              <th className='border-bottom'>Name</th>
              <th className='border-bottom'>email</th>
              <th className='border-bottom'>phone </th>

              <th className='border-bottom'>Action</th>
            </tr>
          </thead>
          <tbody>
            {scholarShipData.map(t => {
              return <TableRow key={`transaction-${t._id}`} {...t} />
            })}
          </tbody>
        </Table>
        <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
      </Card.Body>
    </Card>
  )
}
export const FreeWebinarTable = () => {
  const [scholarShipData, setScholarShipData] = useState([])
  const [id, setId] = useState(null)
  const [reCallApi, setReCallApi] = useState('')

  const FreeWebinarList = async () => {
    await axios
      .get('/api/webinar/getWebinar')
      .then(res => {
        const filterData = res.data.data.filter(item => item.is_paid === false)
        setScholarShipData(filterData)
      })
      .catch(e => alert(e))
  }

  async function deleteFreeWebinar() {
    await callApi({ uriEndPoint: deleteFreeWebinarEndpoint, pathParams: { id: id } })
      .then(() => {
        setReCallApi(Math.floor(Math.random(1) * 100).toString())
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    FreeWebinarList()
  }, [reCallApi])

  const TableRow = props => {
    const { _id, name, email, phone, index, created_at } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{name}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        <td>
          <span className='fw-normal'>{created_at ? moment(created_at).format('YYYY-MM-DD') : '-'}</span>
        </td>

        <td style={{ display: 'flex', gap: 10, flexDirection: 'row' }}>
          <Button style={{ cursor: 'pointer' }} onClick={() => (window.location.href = `#/view-free-webinar/` + _id)}>
            view
          </Button>
          <Button
            style={{ backgroundColor: 'red' }}
            data-toggle='modal'
            data-target='#exampleModalCenter-webinar'
            onClick={() => setId(_id)}
          >
            delete
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Name
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  phone{' '}
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  date{' '}
                </th>

                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {scholarShipData.map((t, index) => {
                return <TableRow key={`transaction-${t._id}`} {...t} index={index} />
              })}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
      <div
        className='modal fade'
        id='exampleModalCenter-webinar'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => deleteFreeWebinar()}
              >
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export const PaidWebinarTable = () => {
  const [scholarShipData, setScholarShipData] = useState([])
  const [id, setId] = useState(null)
  const [reCallApi, setReCallApi] = useState('')

  const PidWebinarList = async () => {
    await axios
      .get('/api/webinar/getWebinar')
      .then(res => {
        const filterData = res.data.data.filter(item => item.is_paid === true)

        setScholarShipData(filterData)
      })
      .catch(e => alert(e))
  }
  async function deletePaidWebinar() {
    await callApi({ uriEndPoint: deleteFreeWebinarEndpoint, pathParams: { id: id } })
      .then(() => {
        setReCallApi(Math.floor(Math.random(1) * 100).toString())
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    PidWebinarList()
  }, [reCallApi])

  const TableRow = props => {
    const { _id, name, email, phone, index, created_at } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{name}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        <td>
          <span className='fw-normal'>{created_at ? moment(created_at).format('YYYY-MM-DD') : '-'}</span>
        </td>

        <td style={{ display: 'flex', gap: 10, flexDirection: 'row' }}>
          <Button style={{ cursor: 'pointer' }} onClick={() => (window.location.href = `#/view-free-webinar/` + _id)}>
            view
          </Button>
          <Button
            style={{ backgroundColor: 'red' }}
            data-toggle='modal'
            data-target='#exampleModalCenter-paidWebinar'
            onClick={() => setId(_id)}
          >
            delete
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Name
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  phone{' '}
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  date{' '}
                </th>

                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {scholarShipData.map((t, index) => {
                return <TableRow key={`transaction-${t._id}`} {...t} index={index} />
              })}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
      <div
        className='modal fade'
        id='exampleModalCenter-paidWebinar'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => deletePaidWebinar()}
              >
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const NotificationTable = () => {
  const [scholarShipData, setScholarShipData] = useState([])
  const [id, setId] = useState(null)
  const [reCallApi, setReCallApi] = useState('')

  const ScholarshipList = async () => {
    await axios
      .get('/api/webinar/getWebinar')
      .then(res => {
        const filterData = res.data.data.filter(item => item.is_paid === true)

        setScholarShipData(filterData)
      })
      .catch(e => alert(e))
  }
  async function deletePaidWebinar() {
    await callApi({ uriEndPoint: deleteFreeWebinarEndpoint, pathParams: { id: id } })
      .then(() => {
        setReCallApi(Math.floor(Math.random(1) * 100).toString())
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    ScholarshipList()
  }, [reCallApi])

  const TableRow = props => {
    const { _id, name, email, phone, index, created_at } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{name}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        <td>
          <span className='fw-normal'>{created_at ? moment(created_at).format('YYYY-MM-DD') : '-'}</span>
        </td>

        <td style={{ display: 'flex', gap: 10, flexDirection: 'row' }}>
          <Button style={{ cursor: 'pointer' }} onClick={() => (window.location.href = `#/view-free-webinar/` + _id)}>
            view
          </Button>
          <Button
            style={{ backgroundColor: 'red' }}
            data-toggle='modal'
            data-target='#exampleModalCenter-paidWebinar'
            onClick={() => setId(_id)}
          >
            delete
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Name
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  phone{' '}
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  date{' '}
                </th>

                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {scholarShipData.map((t, index) => {
                return <TableRow key={`transaction-${t._id}`} {...t} index={index} />
              })}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
      <div
        className='modal fade'
        id='exampleModalCenter-paidWebinar'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => deletePaidWebinar()}
              >
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const ChapterTable = props => {
  const [courses, setCourses] = useState([])
  const { user } = useUserContext()

  // const courseid = props.match.params.id

  useEffect(() => {
    Refresh()
  }, [])
  async function Refresh() {
    await axios
      .post('/api/course/all-chapters', {
        id: props.id
      })
      .then(res => {
        setCourses(res.data)
      })
      .catch(e => alert(e))
  }

  // function DeleteQuiz(id) {
  //   axios
  //     .get(`/api/course/quiz/delete/${id}`)
  //     .then(res => {
  //       console.log('started')
  //       if (res.data.success === true) {
  //         alert('Quiz is Deleted')
  //         Refresh()
  //       }
  //     })
  //     .catch(e => {
  //       alert(e)
  //     })
  // }

  // function Delete(id) {
  //   axios
  //     .get(`/api/course/delete/chapter/${id}`)
  //     .then(res => {
  //       console.log('started')
  //       if (res.data.success === true) {
  //         alert('Chapter is Deleted')
  //         Refresh()
  //       }
  //     })
  //     .catch(e => {
  //       alert(e)
  //     })
  // }

  const TableRow = props => {
    const { _id, chapterName } = props
    // const statusVariant = status === "Paid" ? "success"
    //   : status === "Due" ? "warning"
    //     : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          <span className='fw-normal'>{chapterName}</span>
        </td>

        {user.role === 'super-admin' && (
          <td>
            <button onClick={() => (window.location.href = `#/edit-chapter/` + _id)} className='btn'>
              <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit Chapter
            </button>
          </td>
        )}

        <td>
          <button onClick={() => (window.location.href = `#/all-lessons/` + _id)} className='btn'>
            <FontAwesomeIcon icon={faPlus} className='me-2' /> Add Lesson
          </button>
        </td>

        <td>
          <button onClick={() => (window.location.href = `#/create-quiz/` + _id)} className='btn'>
            <FontAwesomeIcon icon={faPlus} className='me-2' /> Add Quiz
          </button>
        </td>

        {/* <td>
          <button onClick={()=>Delete(_id)} className="btn btn-danger">
          <FontAwesomeIcon  icon={faTrashAlt} className="me-2" /> Remove
          </button>
        </td> */}

        {/* <td>
          <button onClick={()=>DeleteQuiz(_id)} className="btn btn-danger">
          <FontAwesomeIcon  icon={faTrashAlt} className="me-2" /> Delete Quiz
          </button>
        </td> */}
      </tr>
    )
  }

  return (
    <Card border='light' className='table-wrapper table-responsive shadow-sm'>
      <Card.Body style={{ height: 432 }} className='pt-0'>
        <Table hover className='user-table align-items-center'>
          <thead>
            <tr>
              <th className='border-bottom'>Chapter Name</th>

              {user.role === 'super-admin' && <th className='border-bottom'>Edit Chapter</th>}
              <th className='border-bottom'>Add Lesson</th>
              <th className='border-bottom'>Add Quiz</th>
              {/* <th className="border-bottom">Remove Chapter</th> */}
              {/* <th className="border-bottom">Delete Quiz</th> */}
            </tr>
          </thead>
          <tbody>
            {courses.map(t => (
              <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
            ))}

            {/* {courses.map(res=>(
            <p>{res.nameOfCourse}</p>
          ))} */}
          </tbody>
        </Table>
        <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
          {/* <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav> */}
          {/* <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small> */}
        </Card.Footer>
      </Card.Body>
    </Card>
  )
}

export const QuizTable = props => {
  const [courses, setCourses] = useState([])
  const chapterid = props.chapterid

  const [wholeData, setWholeData] = useState([])

  // const [alerts, setAlert] = useState(false)

  const [idf, setIdf] = useState('')
  const { user } = useUserContext()

  useEffect(() => {
    Refresh()
  }, [])
  async function Refresh() {
    await axios
      .get(`/api/course/quiz/cha/${chapterid}`)
      .then(res => {
        setWholeData(res.data)
        setCourses(res.data.questions)

        // console.log(res)
      })
      .catch(e => console.log(e))
  }

  async function Delete(da) {
    const q = courses.filter(r => r._id !== da)

    await axios
      .post(`/api/course/update/quiz/${chapterid}`, {
        chapterid: wholeData.chapterid,
        nameOfCourse: wholeData.nameOfCourse,
        description: wholeData.description,
        time: wholeData.time,

        questions: q
      })
      .then(() => {
        alert('Question is deleted')
        // setAlert(false)
        Refresh()
      })
      .catch(e => alert(e))
  }

  const TableRow = props => {
    const { _id, type, question, answer } = props
    // const statusVariant = status === "Paid" ? "success"
    //   : status === "Due" ? "warning"
    //     : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          <span className='fw-normal'>{question}</span>
        </td>
        <td>
          <span className='fw-normal'>{type === true ? 'True/False' : 'MCQs'}</span>
        </td>
        <td>
          <span className='fw-normal'>{answer}</span>
        </td>

        {user.role === 'super-admin' && (
          <td>
            <button onClick={() => (window.location.href = `#/edit-quiz/` + chapterid + '/' + _id)} className='btn '>
              <FontAwesomeIcon icon={faAd} className='me-2' /> Edit Question
            </button>
          </td>
        )}
        {user.role === 'super-admin' && (
          <td>
            <button
              onClick={() => {
                // setAlert(true)
                setIdf(_id)
              }}
              className='btn btn-danger'
              data-toggle='modal'
              data-target='#quizDelete'
            >
              <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Remove Question
            </button>
          </td>
        )}
      </tr>
    )
  }

  return (
    <>
      {/* {alerts === false ? (
        <></>
      ) : (
        <div className='alert-box'>
          <div className='container'>
            <p>Are you sure want to delete ?</p>
            <div className='buttons'>
              <div className='yes' onClick={() => {}}>
                {' '}
                Yes{' '}
              </div>
              <div className='no' onClick={() => setAlert(false)}>
                {' '}
                Cancel
              </div>
            </div>
          </div>
        </div>
      )} */}

      <div
        className='modal fade'
        id='quizDelete'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='quizDelete'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => Delete(idf)}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>Quiz Name</th>
                <th className='border-bottom'>Type</th>
                <th className='border-bottom'>Answer</th>
                {user.role === 'super-admin' && <th className='border-bottom'>Edit Quiz</th>}
                {user.role === 'super-admin' && <th className='border-bottom'>Remove Quiz</th>}
              </tr>
            </thead>
            <tbody>
              {courses.map(t => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
              ))}

              {/* {courses.map(res=>(
            <p>{res.nameOfCourse}</p>
          ))} */}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
            {/* <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav> */}
            {/* <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small> */}
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const LessonTable = props => {
  const [courses, setCourses] = useState([])

  const [idf, setIDF] = useState('')
  const { user } = useUserContext()

  useEffect(() => {
    Refresh()
  }, [])

  async function Refresh() {
    await axios
      .post('/api/course/all-lessons', {
        id: props.chapterid,
        body: 'front-end-api'
      })
      .then(res => {
        setCourses(res.data)

        // console.log(res)
      })
      .catch(e => console.log(e))
  }

  function Delete(id) {
    axios
      .get(`/api/course/delete/chapter/lesson/${id}?type=front-end-api`)
      .then(res => {
        console.log('started')
        if (res.data.success === true) {
          setIDF('')
          Refresh()
        }
      })
      .catch(e => {
        alert(e)
      })
  }

  const TableRow = props => {
    const { _id, lessonName } = props
    // const statusVariant = status === "Paid" ? "success"
    //   : status === "Due" ? "warning"
    //     : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          <span className='fw-normal'>{lessonName}</span>
        </td>

        {user.role === 'super-admin' && (
          <td>
            <button onClick={() => (window.location.href = `#/edit-lesson/` + _id)} className='btn '>
              <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit Lesson
            </button>
          </td>
        )}

        <td>
          <button
            onClick={() => {
              setIDF(_id)
            }}
            className='btn btn-danger'
            data-toggle='modal'
            data-target='#lessonDelete'
          >
            <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Remove
          </button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <div
        className='modal fade'
        id='lessonDelete'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='lessonDelete'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => Delete(idf)}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>Lesson Name</th>
                {user.role === 'super-admin' && <th className='border-bottom'>Edit Lesson</th>}
                {/* <th className="border-bottom">Remove Lesson</th> */}
              </tr>
            </thead>
            <tbody>
              {courses.map(t => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
              ))}

              {/* {courses.map(res=>(
            <p>{res.nameOfCourse}</p>
          ))} */}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
            {/* <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav> */}
            {/* <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small> */}
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const CourseTable = () => {
  const [courses, setCourses] = useState([])
  const { user } = useUserContext()

  const [alerts, setAlert] = useState(false)

  const [idf, setIDF] = useState('')

  useEffect(() => {
    Refresh()
  }, [])

  async function Refresh() {
    await axios
      .get('/api/course?type=front-end-api')
      .then(res => {
        const data = res.data.courses
        data.sort(function (a, b) {
          let keyA = a.serial
          let keyB = b.serial

          if (keyA < keyB) return -1
          if (keyA > keyB) return 1
          return 0
        })

        setCourses(data)
      })
      .catch(e => alert(e))
  }

  function Delete(id) {
    setAlert(true)

    axios
      .get(`/api/course/delete/${id}?type=front-end-api`)
      .then(res => {
        console.log('started')
        if (res.data.success === true) {
          alert('Course is Deleted')
          Refresh()
          setAlert(false)
          setIDF('')
        }
      })
      .catch(e => {
        alert(e)
      })
  }

  const TableRow = props => {
    const { _id, nameOfCourse, SellingPrice, tutorName, index } = props
    // const statusVariant = status === "Paid" ? "success"
    //   : status === "Due" ? "warning"
    //     : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{nameOfCourse}</span>
        </td>
        <td>
          <span className='fw-normal'>{tutorName}</span>
        </td>

        <td>
          <span className='fw-normal'>₹{SellingPrice}</span>
        </td>

        {user.role === 'super-admin' && (
          <td>
            <button onClick={() => (window.location.href = `#/edit/` + _id)} className='btn btn-warring'>
              <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit Course
            </button>
          </td>
        )}

        <td>
          <button onClick={() => (window.location.href = `#/mychapters/` + _id)} className='btn '>
            <FontAwesomeIcon icon={faPlus} className='me-2' /> Add Content
          </button>
        </td>

        {/* <td>
          <button
            onClick={() => {
              setIDF(_id)
              setAlert(true)
            }}
            className='btn btn-danger'
          >
            <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Remove
          </button>
        </td> */}
      </tr>
    )
  }

  return (
    <>
      {alerts === false ? (
        <></>
      ) : (
        <div className='alert-box'>
          <div className='container'>
            <p>Are you sure want to delete ?</p>
            <div className='buttons'>
              <div
                className='yes'
                onClick={() => {
                  Delete(idf)
                }}
              >
                {' '}
                Yes{' '}
              </div>
              <div className='no' onClick={() => setAlert(false)}>
                {' '}
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>Sr No.</th>
                <th className='border-bottom'>Course Name</th>
                <th className='border-bottom'>Tutor Name</th>
                <th className='border-bottom'>Pricing</th>
                {user.role === 'super-admin' && <th className='border-bottom'>Edit Course</th>}
                <th className='border-bottom'>Add Content</th>
                {/* <th className='border-bottom'>Remove Course</th> */}
              </tr>
            </thead>
            <tbody>
              {courses.map((t, index) => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
              ))}

              {/* {courses.map(res=>(
            <p>{res.nameOfCourse}</p>
          ))} */}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
            {/* <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav> */}
            {/* <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small> */}
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const CommandsTable = () => {
  const TableRow = props => {
    const { name, usage = [], description, link } = props

    return (
      <tr>
        <td className='border-0' style={{ width: '5%' }}>
          <code>{name}</code>
        </td>
        <td className='fw-bold border-0' style={{ width: '5%' }}>
          <ul className='ps-0'>
            {usage.map(u => (
              <ol key={u} className='ps-0'>
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className='border-0' style={{ width: '50%' }}>
          <pre className='m-0 p-0'>{description}</pre>
        </td>
        <td className='border-0' style={{ width: '40%' }}>
          <pre>
            <Card.Link href={link} target='_blank'>
              Read More <FontAwesomeIcon icon={faExternalLinkAlt} className='ms-1' />
            </Card.Link>
          </pre>
        </td>
      </tr>
    )
  }

  return (
    <Card border='light' className='shadow-sm'>
      <Card.Body className='p-0'>
        <Table responsive className='table-centered rounded' style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          <thead className='thead-light'>
            <tr>
              <th className='border-0' style={{ width: '5%' }}>
                Name
              </th>
              <th className='border-0' style={{ width: '5%' }}>
                Usage
              </th>
              <th className='border-0' style={{ width: '50%' }}>
                Description
              </th>
              <th className='border-0' style={{ width: '40%' }}>
                Extra
              </th>
            </tr>
          </thead>
          <tbody>
            {commands.map(c => (
              <TableRow key={`command-${c.id}`} {...c} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}
