import React, { useState } from 'react'
import { Col, Row, Form, Container } from '@themesberg/react-bootstrap'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'

import S3FileUpload from 'react-s3'
window.Buffer = window.Buffer || require('buffer').Buffer

import axios from 'axios'

export default props => {
  const [nameOfCourse, setnameOfCourse] = useState('')
  const [courseid, setCourseId] = useState(props.match.params.id)
  const [clinicalPonits, setClinicalPonits] = useState('')
  const [chapterNumber, setChapterNumber] = useState('')
  const [indianStudentRegularPrice, setIndianStudentRegularPrice] = useState('')
  const [indianStudentSellingPrice, setIndianStudentSellingPrice] = useState('')
  const [indianHomeopathicRegularPrice] = useState('')
  const [indianHomeopathicSellingPrice] = useState('')
  const [internationalRegularPrice, setInternationalRegularPrice] = useState('')
  const [internationalSellingPrice, setInternationalSellingPrice] = useState('')
  const [HeroImg, setHeroImg] = useState('')

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const handleEditorChange = state => {
    setEditorState(state)
  }

  const config = {
    bucketName: 'courseimg',
    region: 'ap-south-1',
    accessKeyId: 'AKIAZTNEOKHHTM2HHSWZ',
    secretAccessKey: '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'
  }

  function UploadImages(event) {
    const image = event.target.files[0]
    S3FileUpload.uploadFile(image, config)
      .then(data => {
        setHeroImg(data.location)
        console.log(data.location)
      })
      .catch(err => console.error(err))
  }

  // function RecieveData(html) {
  //   const data = '' + html + ''
  //   console.log(data)

  //   //  if (data) {
  //   //   const contentState = ContentState.createFromBlockArray(
  //   //     data.contentBlocks
  //   //   )
  //   //   const editorState = EditorState.createWithContent(contentState);
  //   //   setEditorState(editorState);

  //   // }
  // }

  function CourseCreation(e) {
    e.preventDefault()
    convertContentToHTML()
  }

  // function convertToSlug(Text) {
  //   return Text.toLowerCase()
  //     .replace(/ /g, '-')
  //     .replace(/[^\w-]+/g, '')
  // }

  async function convertContentToHTML() {
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    await axios
      .post('/api/course/create/chapter', {
        chapterName: nameOfCourse,
        chapterDes: currentContentAsHTML,
        courseID: courseid,
        clinicalPonits: clinicalPonits,
        chapterNumber: chapterNumber,
        indian_student_regular_price: indianStudentRegularPrice,
        indian_student_selling_price: indianStudentSellingPrice,
        indian_homeopathic_regular_price: indianHomeopathicRegularPrice,
        indian_homeopathic_selling_price: indianHomeopathicSellingPrice,
        international_regular_price: internationalRegularPrice,
        international_selling_price: internationalSellingPrice,
        chapter_thumbnail: HeroImg
      })
      .then(() => {
        alert('Chapter Created')

        window.location.href = `#/mychapters/${courseid}`

        // console.log(res)
      })
      .catch(e => alert(e))
  }

  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>Create Chapter</h1>
            <p className='mb-0'>
              Here you can create a chapter for SHAH <a href={'#/mychapters/' + courseid}>Back to chapters</a>
            </p>
          </Col>
        </Row>
        <Form onSubmit={CourseCreation}>
          <Form.Group className='mb-3'>
            <Form.Label>Chapter Name</Form.Label>
            <Form.Control
              type='text'
              value={nameOfCourse}
              onChange={e => setnameOfCourse(e.target.value)}
              placeholder='Your Chapter name'
              required
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Add Description</Form.Label>
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName='demo-wrapper'
              editorClassName='demo-editor'
              toolbarClassName='toolbar-class'
            />
            <Form.Group style={{ marginTop: 4 }} className='mb-3'>
              <Form.Label>Clinical Points ( 1 = default | 0 = hide )</Form.Label>
              <Form.Control
                value={clinicalPonits}
                onChange={e => setClinicalPonits(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Clinical Points'
                required
              />
            </Form.Group>
            <Form.Group style={{ marginTop: 4 }} className='mb-3'>
              <Form.Label>Chapter Number </Form.Label>
              <Form.Control
                value={chapterNumber}
                onChange={e => setChapterNumber(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Chapter Number'
                required
              />
            </Form.Group>

            <Form.Label style={{ marginTop: 4 }}>Chapter Hero Image</Form.Label>
            <div className='mb-3'>
              {HeroImg?.length === 0 ? (
                <input onChange={UploadImages} style={{ marginTop: 4 }} type='file' placeholder='Chapter Img URL' />
              ) : (
                <div>
                  <p className='btn btn-success'>Image Added</p>
                  <p
                    onClick={() => {
                      setHeroImg('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </div>
              )}
            </div>
            <Form.Group className='mb-3'>
              <Form.Label>Indian Regular Price (INR)</Form.Label>
              <Form.Control
                type='text'
                value={indianStudentRegularPrice}
                onChange={e => setIndianStudentRegularPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Indian Discounted Price (INR)</Form.Label>
              <Form.Control
                type='text'
                value={indianStudentSellingPrice}
                onChange={e => setIndianStudentSellingPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group>
            {/* <Form.Group className='mb-3'>
              <Form.Label>Indian Homeopaths Regular Price (INR)</Form.Label>
              <Form.Control
                type='text'
                value={indianHomeopathicRegularPrice}
                onChange={e => setIndianHomeopathicRegularPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Indian Homeopaths Discounted Price (INR)</Form.Label>
              <Form.Control
                type='number'
                value={indianHomeopathicSellingPrice}
                onChange={e => setIndianHomeopathicSellingPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group> */}
            <Form.Group className='mb-3'>
              <Form.Label>International Regular Price (Dollar)</Form.Label>
              <Form.Control
                type='number'
                value={internationalRegularPrice}
                onChange={e => setInternationalRegularPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>International Discounted Price (Dollar)</Form.Label>
              <Form.Control
                type='number'
                value={internationalSellingPrice}
                onChange={e => setInternationalSellingPrice(e.target.value)}
                placeholder='Lesson Regular Price'
              />
            </Form.Group>
            <Form.Group style={{ marginTop: 4, display: 'none' }} className='mb-3'>
              <Form.Label>Details</Form.Label>
              <Form.Control
                value={courseid}
                onChange={e => setCourseId(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Your Course id'
                required
              />
            </Form.Group>
            <button className='btn btn-info' style={{ marginTop: 12 }} type='submit'>
              Add Chapter
            </button>
          </Form.Group>
        </Form>
      </Container>
    </article>
  )
}
