import React, { useEffect, useState } from 'react'
import { callApi } from '../../utils/api-utils'
import { Col, Form, Row } from '@themesberg/react-bootstrap'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    }
  }
}

const viewEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/user/get-user/:id'
}

export default props => {
  const [viewUserReport, setViewUserReport] = useState({})

  console.log(`🚀  ~ file: ViewReportUser.js:21 ~ viewUserReport:`, viewUserReport)

  const callApiFunc = async () => {
    await callApi({ uriEndPoint: viewEndPoint, pathParams: { id: props?.match?.params?.id || null } })
      .then(res => {
        setViewUserReport(res?.data || '')
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApiFunc()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>View User Report</h4>
            <button className='btn btn-primary' onClick={() => window.history.back()}>
              Back
            </button>
          </div>
          <h5>Student Type:{viewUserReport?.student_select?.split('_')?.join(' ')?.toUpperCase() || '-'}</h5>
          <Row>
            <Col className='d-block mb-6 mb-md-0' xs={6}>
              <Form.Label> Name : {viewUserReport.legal_name}</Form.Label>
              <br />
              <Form.Label>
                Address : {viewUserReport.customer_address}, {viewUserReport.customer_state},{' '}
                {viewUserReport.customer_city}, {viewUserReport.customer_pincode}
              </Form.Label>
              <br />
              <Form.Label>
                Student Contact : {viewUserReport.email} | Phone : {viewUserReport.phone}
              </Form.Label>
              <br />
              <Form.Label>Alternative Phone : {viewUserReport.altPhone}</Form.Label>
              <br />
              <Form.Label>BirthDate : {viewUserReport.dob}</Form.Label>
              <br />
              <Form.Label>Gender : {viewUserReport.gender}</Form.Label>
              <br />
            </Col>
            <Col className='d-block mb-6 mb-md-0' xs={6}>
              <Form.Label>College: {viewUserReport.college_name}</Form.Label>
              <br />
              <Form.Label>College Website: {viewUserReport.collage_website}</Form.Label>
              <br />
              <Form.Label>College City: {viewUserReport.college_city}</Form.Label>
              <br />
              <Form.Label>College State: {viewUserReport.college_state}</Form.Label>
              <br />
              <Form.Label>College Pincode: {viewUserReport.collage_pincode}</Form.Label>
              <br />
              <Form.Label>Course: {viewUserReport.course}</Form.Label>
              <br />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
