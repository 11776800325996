import React, { useEffect, useState } from 'react'
import { callApi } from '../../utils/api-utils'
import moment from 'moment-timezone'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const viewEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/user/user-question/:id'
}

export default props => {
  const [addFiled, setAddFiled] = useState({})

  console.log(`🚀  ~ file: ViewQuestions.js:36 ~ addFiled:`, addFiled)

  const callApiFunc = async () => {
    await callApi({ uriEndPoint: viewEndPoint, pathParams: { id: props?.match?.params?.id || null } })
      .then(res => {
        setAddFiled(res?.data || '')
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApiFunc()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <div className='d-flex justify-content-between align-items-center'></div>
          <div className='card'>
            <div className='d-flex justify-content-between align-items-center card-header bg-success text-white'>
              <h2>View Information</h2>
              <button className='btn btn-primary' onClick={() => window.history.back()}>
                Back
              </button>
            </div>
            <div className='card-body'>
              <div className='info-item'>
                <span className='label font-weight-bold me-2'>Name:</span>
                <span className='value'>{addFiled?.user_name || ''}</span>
              </div>

              <div className='info-item'>
                <span className='label font-weight-bold me-2'>Email:</span>
                <span className='value'>{addFiled?.email || ''}</span>
              </div>

              <div className='info-item'>
                <span className='label font-weight-bold me-2'>Phone:</span>
                <span className='value'>{addFiled?.phone || ''}</span>
              </div>
              <div className='info-item'>
                <span className='label font-weight-bold me-2'>Date:</span>
                <span className='value'>
                  {addFiled?.created_at ? moment(addFiled?.created_at).format('DD-MM-YYYY') : ''}
                </span>
              </div>

              <div className='info-item'>
                <span className='label font-weight-bold me-2'>Question:</span>
                <span className='value'>{addFiled?.question || ''}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
